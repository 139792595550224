import React, { useEffect, useState } from "react";
import "./App.css"; // Ensure your CSS file is named App.css

const App = () => {
  const hackingMessages = [
    "You are hacked...",
    "... you have 2 hours to return the credentials of this facebook account ..",
    "... otherwise all your data, including images will compromised...",
    "... system breach detected",
    "... unauthorized access in progress",
    ".. security compromised",
    "... data encryption in process",
    "... intrusion alert",
    "... malware detected",
    "... firewall disabled",
    "... password decrypted",
    "... you have 2 hours to return the Network security bypassed",
  ];

  const [messageIndex, setMessageIndex] = useState(0);
  const [typedText, setTypedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < hackingMessages[messageIndex].length) {
      const typingTimer = setTimeout(() => {
        setTypedText((prev) => prev + hackingMessages[messageIndex][index]);
        setIndex((prev) => prev + 1);
      }, 150); // Speed of typing, adjust as needed

      return () => clearTimeout(typingTimer);
    } else if (index === hackingMessages[messageIndex].length) {
      const changeMessageTimer = setTimeout(() => {
        setMessageIndex(
          (prevIndex) => (prevIndex + 1) % hackingMessages.length
        );
        setTypedText("");
        setIndex(0);
      }, 2000); // 2 seconds suspense before showing the next message

      return () => clearTimeout(changeMessageTimer);
    }
  }, [index, messageIndex]);

  return (
    <div className="compromised-container">
      <p className="typing-text">{typedText}</p>
    </div>
  );
};

export default App;
